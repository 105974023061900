import { useStore } from "@nanostores/preact";
import {
  hasNoSearchResults,
  searchBarWidth,
  searchResults,
  searchText,
} from "stores/search";

const NavbarSearchResults = () => {
  const $searchText = useStore(searchText);
  const $searchResults = useStore(searchResults);
  const $hasNoSearchResults = useStore(hasNoSearchResults);
  const $searchWidth = useStore(searchBarWidth);

  return (
    $searchText.length > 0 &&
    ($searchResults.length > 0 || $hasNoSearchResults) && (
      <div
        style={{ width: $searchWidth }}
        className={`scrollbar fixed z-50 mt-2 flex max-h-60 flex-col overflow-y-auto rounded-lg bg-neutral-900 px-4 py-3 outline outline-1 outline-neutral-700`}
      >
        {hasNoSearchResults && !$searchResults.length && (
          <span className="my-1 w-full rounded-lg bg-neutral-200/5 p-4 text-center font-bold text-white transition-colors duration-100">
            no results found
          </span>
        )}
        {$searchResults.map((it) => (
          <a
            rel={"prefetch"}
            as={"page"}
            href={it.href}
            onClick={() => {
              searchText.set("");
              searchResults.set([]);
              hasNoSearchResults.set(false);
            }}
            className="my-1 w-full rounded-lg bg-neutral-200/5 p-4 text-white outline-none transition-all duration-100 hover:bg-neutral-200/10 focus:outline focus:outline-2 focus:outline-purple-500 active:scale-[.98]"
          >
            {it.label}
          </a>
        ))}
      </div>
    )
  );
};

export default NavbarSearchResults;
